.navbar {
  margin-left: 132rem;
  ul {
    display: flex;
    li {
      a {
        color: #a7a8aa;
        leading-trim: both;
        text-edge: cap;
        font-size: 16rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-decoration: none;
        margin-right: 70rem;
        transition: 0.3s;
        &:hover,
        &.active {
          color: #fff;
        }
      }
    }
  }
}
