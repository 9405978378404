.promotions-filter {
  display: flex;
  align-items: center;
  width: 100%;

  &-select {
    margin-right: 12rem;
    position: relative;

    &-wrap {
      display: flex;
      height: 48rem;
      align-items: center;
      border: 1px solid #f2f4f6;
      border-radius: 8rem;
      padding: 0px 15rem;
      cursor: pointer;
    }

    span {
      font-weight: 500;
      font-size: 14rem;
      color: #8f969c;
    }

    strong {
      font-weight: 500;
      font-size: 14rem;
      color: #000;
      margin-left: 10rem;
    }

    &__arrow {
      margin-left: 10rem;

      svg {
        width: 18rem;
        height: 18rem;
        transition: 0.5s;
      }
      &.active {
        svg {
          transform: rotate(180deg);
        }
      }
    }

    &__icon {
      margin-left: 10rem;

      svg {
        width: 18rem;
        height: 18rem;
      }
    }
  }

  &-clear {
    border: 1px solid #f43535;
    border-radius: 100px;
    width: 88rem;
    height: 48rem;
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 500;
    font-size: 16rem;
    text-align: center;
    color: #f43535;
    cursor: pointer;
  }

  &-dropdown {
    position: absolute;
    top: calc(100% + 8rem);
    left: 0;
    box-shadow: 0 4px 33px -12px rgba(16, 16, 16, 0.12);
    background: #fff;
    border-radius: 10rem;
    padding: 24rem 22rem;
    min-width: 100%;
    z-index: 10;

    &-wrap {
      height: 400rem;
      overflow-y: auto;
      min-width: 170rem;
      &::-webkit-scrollbar {
        width: 4px;
        display: block;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #eee;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }
    &__title {
      font-weight: 600;
      font-size: 18rem;
      color: #000;
      margin-bottom: 24rem;
    }

    &__btn {
      width: 100%;
      background: #000;
      border-radius: 100rem;
      height: 48rem;
      cursor: pointer;
      font-weight: 600;
      font-size: 16rem;
      text-align: center;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-time {
      display: flex;
      align-items: center;
      margin-bottom: 16rem;

      input {
        width: 75rem;
        height: 46rem;
        border: 1px solid #f2f4f6;
        border-radius: 9rem;
        text-align: center;
        font-weight: 500;
        font-size: 16rem;
        color: #000;

        &:focus {
          border: 1px solid #000;
        }
      }

      span {
        font-weight: 500;
        font-size: 16rem;
        color: #cad4dd;
        margin: 0 16rem;
      }
    }

    .analytics-top-date-block {
      position: static;
      padding: 0;
      box-shadow: none;

      &:after {
        display: none;
      }

      .react-datepicker__current-month {
        font-family: 'Italian Plate No2 Expanded', sans-serif;
        font-weight: 600;
        font-size: 18rem;
        color: #000;
        margin-bottom: 20rem;
      }

      .react-datepicker__navigation--previous {
        left: 0;
        top: 6rem;
      }

      .react-datepicker__navigation--next {
        right: 0;
        top: 6rem;
      }

      .react-datepicker__day-names .react-datepicker__day-name {
        font-weight: 600;
        font-size: 12rem;
        text-transform: uppercase;
        text-align: center;
        color: #cad4dd;
        border: none;
        width: 36rem;
        height: 20rem;
        margin-bottom: 6rem;
      }

      .react-datepicker__header {
        border: none;
      }

      .react-datepicker__day {
        width: 36rem;
        height: 36rem;
        font-weight: 500;
        font-size: 16rem;
        line-height: 36rem;
        text-align: center;
        margin-bottom: 6rem;
      }

      .react-datepicker__day--disabled {
        color: #8f969c;
      }
    }
  }

  &-checkbox {
    display: flex;
    margin-bottom: 20rem;
    cursor: pointer;

    &__icon {
      background: #fff;
      border: 1px solid #f2f4f6;
      border-radius: 5rem;
      width: 20rem;
      height: 20rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8rem;
      transition: 0.5s;

      svg {
        height: 10rem;
        width: 10rem;
        opacity: 0;
        transition: 0.5s;
      }
    }

    &.active {
      .promotions-filter-checkbox__icon {
        background: #000;
        border: 1px solid #000;

        svg {
          opacity: 1;
        }
      }
    }
  }
  &-moderators {
    margin-left: 12rem;
    cursor: pointer;
  }
}
