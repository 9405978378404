.aiPrompts {
  .input {
    span {
      font-size: 16rem;
      font-weight: 600;
      margin-bottom: 5rem;
      display: block;
    }
  }
  input {
    width: 100%;
    border: 1px solid #f2f4f6;
    border-radius: 9rem;
    padding: 0rem 16rem;

    height: 46rem;
    font-family: 'Italian Plate No2 Expanded', sans-serif;
    font-weight: 500;
    font-size: 16rem;
    color: #000;
  }
  select {
    width: 100%;
    border: 1px solid #f2f4f6;
    border-radius: 9rem;
    padding: 0rem 16rem;

    height: 46rem;
    font-family: 'Italian Plate No2 Expanded', sans-serif;
    font-weight: 500;
    font-size: 16rem;
    color: #000;
  }
  h3 {
    font-weight: 700;
    font-size: 24rem;
    margin-bottom: 16rem;
  }
  &-nav {
    display: flex;
    margin-bottom: 16rem;

    a {
      height: 35rem;
      padding: 0 14rem;
      background: transparent;
      display: flex;
      align-items: center;
      color: #a7a8aa;
      font-weight: 500;
      line-height: 1.3;
      transition: 0.3s;
      margin-right: 6rem;
      font-size: 16rem;

      &:hover,
      &.active {
        background: #fff;
        border-radius: 8rem;
        color: #000;
      }
    }
  }
  textarea {
    width: 100%;
    border: 1px solid #f2f4f6;
    border-radius: 9rem;
    padding: 16rem;

    height: 200rem;
    font-family: 'Italian Plate No2 Expanded', sans-serif;
    font-weight: 500;
    font-size: 16rem;
    color: #000;
    outline: none;
  }

  &-inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10rem;
    margin-bottom: 10rem;
  }
  .btn {
    margin: 5rem auto 0;
    border-radius: 100rem;
    background: var(--black, #000);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48rem;
    width: 100%;
    color: var(--white, #fff);
    text-align: center;
    font-family:
      Italian Plate No2 Expanded,
      sans-serif;
    font-size: 16rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    outline: none;
    border: none;
  }
}
