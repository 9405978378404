.orders {
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24rem;

    &-item {
      display: flex;
      margin-left: 30rem;
      align-items: center;

      span {
        color: #000;
        text-align: right;
        font-family:
          Italian Plate No2 Expanded,
          sans-serif;
        font-size: 20rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-right: 5rem;
      }

      strong {
        color: #000;
        font-family:
          Italian Plate No2 Expanded,
          sans-serif;
        font-size: 20rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    &-right {
      display: flex;
    }
  }

  &-block {
    display: flex;
    border-radius: 16rem;
    background: #fff;
    padding: 32rem 24rem;
    justify-content: space-between;
    margin-bottom: 25rem;
    &-item {
      display: flex;

      svg {
        width: 32rem;
        height: 32rem;
        margin-right: 10rem;
      }
      &-info {
        display: flex;
        flex-direction: column;
        padding-top: 8rem;
        span {
          color: #a7a8aa;
          font-family:
            Italian Plate No2 Expanded,
            sans-serif;
          font-size: 14rem;
          font-style: normal;
          font-weight: 500;
          line-height: 120%;
          margin-bottom: 7.5rem;
          white-space: nowrap;
        }
        strong {
          color: #000;
          font-family:
            Italian Plate No2 Expanded,
            sans-serif;
          font-size: 24rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
}
