.products {
  &-table {
    &__img {
      img {
        border: 1px solid #f5f5f5;
        border-radius: 9rem;
        width: 60rem;
        height: 60rem;
        object-fit: cover;
      }
    }
    &-price {
      span {
        text-decoration: line-through;
        margin-left: 8rem;
      }
    }
    &-info {
      h4 {
        font-weight: 600;
        font-size: 16rem;
        line-height: 120%;
        color: #000;
        width: 271rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 6rem;
      }

      p {
        font-weight: 400;
        font-size: 16rem;
        color: #8f969c;
        width: 271rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &-bottom {
        display: flex;
        align-items: center;
      }

      &__status {
        display: flex;
        align-items: center;
        margin-right: 12rem;
        padding-right: 12rem;
        font-weight: 500;
        font-size: 12rem;
        color: #000;
        position: relative;

        span {
          width: 8rem;
          height: 8rem;
          background: #17cc37;
          border-radius: 50%;
          margin-right: 7rem;
          margin-top: 1rem;
        }

        &:after {
          position: absolute;
          content: '';
          width: 1rem;
          height: 14rem;
          background: #cad4dd;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      ul {
        display: flex;
        li {
          display: flex;
          align-items: center;
          width: 50rem;
          font-weight: 500;
          font-size: 12rem;
          color: #000;
          margin-right: 12rem;
          svg {
            width: 14rem;
            height: 14rem;
            margin-right: 6rem;
            margin-top: 1rem;
          }
        }
      }
    }
  }
}
