.products-videos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10rem;

  .creatives-item__date {
    font-weight: 500;
    font-size: 14rem;
    color: #a7a8aa;
    margin-bottom: 8rem;
  }

  .creatives-item__title {
    height: 40rem;
    overflow: hidden;
    margin-bottom: 34rem;
  }

  .creatives-item__duration {
    font-weight: 400;
    font-size: 14rem;
    color: #8f969c;
    margin: 0 15rem 6rem;
  }

  .creatives-item__img {
    aspect-ratio: 260/461;
    display: block;
    > svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-failed {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      background: #fff;
      font-family: 'Italian Plate No2 Expanded', sans-serif;
      svg {
        width: 30rem;
        height: 30rem;
        margin-bottom: 12rem;
      }

      border-left: 1px solid #f2f4f6;
      border-right: 1px solid #f2f4f6;
      border-top: 1px solid #f2f4f6;
      border-radius: 12rem 12rem 0 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      color: #000;
    }
    &-render {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      background: #fff;

      svg {
        width: 30rem;
        height: 30rem;
        margin-bottom: 12rem;
      }
      font-family: 'Italian Plate No2 Expanded', sans-serif;
      border-left: 1px solid #f2f4f6;
      border-right: 1px solid #f2f4f6;
      border-top: 1px solid #f2f4f6;
      border-radius: 12rem 12rem 0 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 100%;
      color: #000;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .creatives-item__ai {
    display: flex;

    span {
      margin-right: 4rem;
    }

    &--failed {
      span {
        border-radius: 10rem;
        padding: 0px 12rem;
        height: 32rem;
        background: #f2f4f6;
        font-weight: 600;
        font-size: 16rem;
        color: #ef2c4f;

        i {
          width: 8rem;
          height: 8rem;
          background: #ef2c4f;
          border-radius: 50%;
          margin-right: 6rem;
        }
      }

      background: none;
    }
    &--render {
      span {
        border-radius: 10rem;
        padding: 0px 12rem;
        height: 32rem;
        background: #f2f4f6;
        font-weight: 600;
        font-size: 16rem;
        color: #000;

        i {
          width: 8rem;
          height: 8rem;
          background: #8f969c;
          border-radius: 50%;
          margin-right: 6rem;
        }
      }

      background: none;
    }
    &--finished {
      span {
        border-radius: 10rem;
        padding: 0px 12rem;
        height: 32rem;
        background: #effdf6;
        font-weight: 600;
        font-size: 16rem;
        color: #000;

        i {
          width: 8rem;
          height: 8rem;
          background: #17cc37;
          border-radius: 50%;
          margin-right: 6rem;
        }
      }
    }
  }
}
