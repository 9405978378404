.videos {
  &-table {
    background: #fff;
    padding: 24rem;
    border-radius: 16rem;
    grid-template-columns: repeat(4, 1fr);
    .creatives-item {
      &:hover {
        box-shadow: none;
      }
    }
  }
}
