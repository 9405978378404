.videos {
  &-block {
    display: flex;
    border-radius: 16rem;
    background: var(--white, #fff);
    padding: 32rem;
    margin-bottom: 41rem;
    &-wrapper {
      display: flex;
      justify-content: space-between;
    }
    &-wrap {
      display: flex;
      grid-gap: 48rem;
      padding-right: 48rem;
      margin-right: 48rem;
      position: relative;
      &:after {
        position: absolute;
        content: '';
        width: 1rem;
        height: 43rem;
        background: #cad4dd;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      &:last-child {
        margin-right: 0;
        padding-right: 0;
        &:after {
          display: none;
        }
      }
    }
    &-item {
      width: 110rem;
      display: flex;
      flex-direction: column;
      font-family: 'Italian Plate No2 Expanded', sans-serif;
      strong {
        font-weight: 600;
        font-size: 24rem;
        color: #000;
        margin-bottom: 5rem;
        display: block;
        &.gray {
          color: #cad4dd;
        }
      }
      span {
        font-weight: 500;
        font-size: 14rem;
        color: #a7a8aa;
        display: block;
      }
    }
  }
}
