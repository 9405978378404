.spinner {
  width: 50rem;
  height: 50rem;
  border-radius: 50%;
  border: 4rem solid #f2f4f6;
  border-top: 4rem solid #000;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
