.audience {
  max-height: 100vh;
  overflow: auto;
  padding: 31rem 40rem;

  .table .table-body-cell:first-child {
    padding-top: 26rem;
  }
  &-top {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 50rem;
    &__title {
      color: #000;
      font-family:
        Italian Plate No2 Expanded,
        sans-serif;
      font-size: 16rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &__btn {
      display: flex;
      align-items: center;
      padding: 14rem 24rem;
      cursor: pointer;
      border-radius: 100rem;
      background: #000;
      color: var(--white, #fff);
      text-align: center;
      font-family:
        Italian Plate No2 Expanded,
        sans-serif;
      font-size: 16rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
      svg {
        margin-left: 10rem;
      }
    }
  }
  &-table-edit {
    border-radius: 100rem;
    border: 1rem solid #e5e8ec;
    padding: 12rem 22rem;
    color: #000;
    text-align: center;
    font-family:
      Italian Plate No2 Expanded,
      sans-serif;
    font-size: 16rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    margin: -12rem 0;
  }
  &-modal {
    &-close {
      position: absolute;
      top: 24rem;
      right: 24rem;
      cursor: pointer;
    }

    &__title {
      color: #000;
      font-family:
        Italian Plate No2 Expanded,
        sans-serif;
      font-size: 28rem;
      font-style: normal;
      font-weight: 700;
      line-height: 125%;
      margin-bottom: 32rem;
    }

    &-top {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 12rem;
      margin-bottom: 39rem;

      label {
        display: block;
        width: 100%;

        span {
          color: #000;
          font-family:
            Italian Plate No2 Expanded,
            sans-serif;
          color: #a7a8aa;
          font-size: 14rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-bottom: 10rem;
          display: block;
        }

        input {
          width: 100%;
          height: 48rem;
          padding: 0 16rem;
          border-radius: 9rem;
          border: 1rem solid #f2f4f6;
          color: #000;
          font-family:
            Italian Plate No2 Expanded,
            sans-serif;
          font-size: 16rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1;
          &::placeholder {
            font-family:
              Italian Plate No2 Expanded,
              sans-serif;
            color: #a7a8aa;
            line-height: 1;
            opacity: 1;
          }
        }
      }
    }

    &-criteries {
      &__title {
        color: #000;
        font-family:
          Italian Plate No2 Expanded,
          sans-serif;
        font-size: 21rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 20rem;
      }

      &-wrap {
        display: grid;
        grid-template-columns: 1fr auto 493rem;
        grid-gap: 10rem;
        margin-bottom: 24rem;
      }

      &-add {
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #000;
        text-align: center;
        font-family:
          Italian Plate No2 Expanded,
          sans-serif;
        font-size: 16rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 40rem;

        svg {
          width: 16rem;
          height: 16rem;
          margin-left: 8rem;
        }
      }

      .equal {
        color: #000;
        font-family:
          Italian Plate No2 Expanded,
          sans-serif;
        font-size: 20rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        line-height: 46rem;
      }

      .css-1u9des2-indicatorSeparator {
        display: none;
      }
    }

    &-option {
      display: grid;
      grid-gap: 12rem;
      > div {
        display: grid;
        grid-template-columns: 342rem 1fr 46rem;
        grid-gap: 12rem;
      }
      &-line {
        width: 46rem;
        height: 46rem;
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          width: 6rem;
          height: 6rem;
          position: relative;
          border-radius: 50%;
          background: #f2f4f6;
          &:after {
            position: absolute;
            width: 2rem;
            height: 31rem;
            content: '';
            background: #f2f4f6;
            left: 50%;
            transform: translateX(-50%);
            top: calc(100% + 2rem);
          }
          &:before {
            position: absolute;
            width: 2rem;
            height: 31rem;
            content: '';
            background: #f2f4f6;
            left: 50%;
            transform: translateX(-50%);
            bottom: calc(100% + 2rem);
          }
        }
      }
      &-add {
        width: 46rem;
        height: 46rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12rem;
        background: var(--gray, #f2f4f6);
        margin-left: auto;
        cursor: pointer;

        svg {
          width: 20rem;
          height: 20rem;
        }
      }

      &-delete {
        width: 46rem;
        height: 46rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 12rem;
        background: var(--gray, #f2f4f6);

        cursor: pointer;

        svg {
          width: 20rem;
          height: 20rem;
        }
      }
    }

    &-action {
      display: flex;
      align-items: center;
    }

    p {
      margin-left: auto;
      color: #a7a8aa;
      font-family:
        Italian Plate No2 Expanded,
        sans-serif;
      font-size: 14rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-right: 51rem;
    }

    &__cancel {
      border-radius: 100rem;
      border: 1rem solid #f2f4f6;
      display: flex;
      padding: 14rem 24rem;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #000;
      text-align: center;
      font-family:
        Italian Plate No2 Expanded,
        sans-serif;
      font-size: 16rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &__save {
      border-radius: 100rem;
      border: 1rem solid #000;
      background: #000;
      display: flex;
      padding: 14rem 24rem;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #fff;
      text-align: center;
      font-family:
        Italian Plate No2 Expanded,
        sans-serif;
      font-size: 16rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-left: 8rem;
    }
    &__delete {
      border-radius: 100rem;
      border: 1rem solid #d82b06;

      display: flex;
      padding: 14rem 24rem;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #d82b06;
      text-align: center;
      font-family:
        Italian Plate No2 Expanded,
        sans-serif;
      font-size: 16rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
