.sidebar {
  width: 1254rem;
  background: #f2f4f6;
  box-shadow: none;
  position: relative;
  z-index: 3 !important;

  &-wrapper {
    display: grid;
    grid-template-columns: 54rem calc(100% - 54rem);
  }

  &-close {
    width: 36rem;
    height: 36rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 44rem;
    cursor: pointer;
  }

  .lists__container {
    background-color: #fff;
    position: relative;
    border-radius: 16px;
    padding: 22px 32px;

    .table-top {
      top: 30px;
      right: 32px;
      position: absolute;
    }

    table tr th:first-child,
    table tr td:first-child {
      padding-left: 6px;
    }
  }

  &-nav {
    background: var(--white, #fff);
    min-height: 100vh;
    height: 100%;
    padding: 20rem 9rem;

    .MuiTabs-flexContainer {
      flex-direction: column;
    }

    .MuiTab-root {
      border-radius: 10rem;
      transition: 0.3s;
      margin-bottom: 20rem;
      width: 36rem;
      height: 36rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      min-width: 36rem;
      min-height: 36rem;

      svg {
        width: 36rem;
        height: 36rem;
        opacity: 0.24;
        transition: 0.3s;

        .stroke {
          transition: 0.3s;
          stroke: #000;
        }

        .fill {
          transition: 0.3s;
          fill: #000;
        }

        .white {
          transition: 0.3s;
          fill: #000;
        }
      }
    }
    .MuiTab-root:hover {
      background: var(--gray-light, #f2f4f6);
    }
    .MuiTab-root.Mui-selected {
      background: #000;

      svg {
        opacity: 1;

        .stroke {
          stroke: #fff;
        }

        .fill {
          fill: #fff;
        }

        .white {
          fill: #fff;
        }
      }
    }

    .MuiTabs-indicator {
      display: none;
    }
  }

  &-right {
    width: 100%;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 24rem 40rem 0;

    [role='tabpanel'] {
      overflow-y: auto;
      padding-right: 20rem;
      padding-bottom: 24rem;
      width: calc(100% + 20rem);
      &::-webkit-scrollbar {
        width: 4rem;
        height: 4rem;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10rem;
        background: rgba(213, 218, 224, 0.87);
      }
    }

    .lists-tabs {
      display: flex;
      font-size: 12rem;
      gap: 5px;
      button {
        border-radius: 100rem;
        border: 1rem solid var(--Gray-light, #f2f4f6);
        padding: 12rem 16rem;
        cursor: pointer;
        background-color: #fff;
        font-weight: 600;

        &::after {
          content: attr(data-count);
          padding: 4rem 6rem;
          background: #f2f4f6;
          border-radius: 100rem;
          color: #000;
          font-size: 10rem;
          margin-left: 6rem;
        }

        &.active {
          border: 1rem solid var(--Gray-Mid, #cad4dd);
          &::after {
            background: #000;
            color: #fff;
          }
        }
      }
    }
  }

  &-content {
    flex: 1;
    overflow: auto;
  }

  .swiper {
    width: 100%;
    // height: 100%;
  }

  .swiper-slide {
    height: auto;
    box-sizing: border-box;
    padding: 24rem 40rem;
  }

  .swiper-vertical > .swiper-scrollbar,
  .swiper-scrollbar.swiper-scrollbar-vertical {
    width: 5rem;
    border-radius: 10rem;
    background: transparent;
    right: 14rem;
  }

  .swiper-scrollbar-drag {
    border-radius: 10rem;
    background: rgba(213, 218, 224, 0.87);
  }

  &-top {
    padding: 20rem 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 78rem;
    min-height: 78rem;

    ul {
      display: flex;

      li {
        border-radius: 10rem;
        background: var(--white, #fff);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 10rem;
        position: relative;
        font-size: 12rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        transition: box-shadow 0.3s;
        padding: 9rem;

        &:hover {
          box-shadow: 0rem 7rem 52rem -2rem rgba(0, 0, 0, 0.12);
          span {
            display: flex;
          }
        }
        &.delete {
          background: #f43535;
          transition: background 0.3s;

          &:hover {
            background: #cc2c2c;
            span {
              right: 0;
              &::after {
                right: 10rem;
              }
            }
          }
        }

        // span {
        //   position: absolute;
        //   top: calc(100% + 12rem);
        //   height: 28rem;
        //   padding: 0 12rem;
        //   display: none;
        //   align-items: center;
        //   background: #000;
        //   color: #fff;
        //   text-overflow: ellipsis;
        //   font-family: Italian Plate No2 Expanded;
        //   font-size: 14rem;
        //   font-style: normal;
        //   font-weight: 300;
        //   line-height: normal;
        //   white-space: nowrap;
        //   border-radius: 5rem;
        //   right: -13rem;

        //   &::after {
        //     display: inline-block;
        //     width: 0;
        //     height: 0;
        //     border-style: solid;
        //     border-width: 0 9rem 10rem 9rem;
        //     border-color: transparent transparent #000 transparent;
        //     position: absolute;
        //     content: '';
        //     bottom: 100%;
        //     right: 23rem;
        //   }
        // }
      }
    }
  }

  .css-19kzrtu {
    padding: 0;
  }
}

.modal {
  background: rgba(#000, 0.22);

  .input__container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6rem;
    width: 100%;
    margin-top: 12rem;
    &:first-child {
      margin-top: 0;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Убираем стрелки в Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    input {
      padding: 15rem 16rem;
      border-radius: 9rem;
      border: 1rem solid #f2f4f6;
      font-size: 16rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    label {
      color: #a7a8aa;
      font-size: 14rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .input-btn {
      position: absolute;
      background: #000;
      color: #fff;
      font-size: 14rem;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      padding: 2.5rem 10rem;
      border: none;
      right: 16rem;
      bottom: 12.5rem;
      border-radius: 66rem;
      cursor: pointer;
    }

    .MuiSnackbarContent-message {
      font-size: 16rem;
    }
  }

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-icon {
    width: 80rem;
    height: 80rem;
    margin-bottom: 24rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &-title {
    color: #000;
    text-align: center;
    font-size: 28rem;
    font-style: normal;
    font-weight: 700;
    line-height: 125%;
    margin-bottom: 32rem;
  }

  &-text {
    color: #000;
    text-align: center;
    font-size: 16rem;
    font-style: normal;
    font-weight: 400;
    line-height: 155%;
    margin-bottom: 32rem;
  }

  &-action {
    display: flex;
    justify-content: center;
    margin-top: 32rem;
  }

  &__cancel {
    cursor: pointer;
    width: 160rem;
    height: 61rem;
    border-radius: 66rem;
    border: 1rem solid #e5e8ec;
    color: #000;
    text-align: center;
    font-family:
      Italian Plate No2 Expanded,
      sans-serif;
    font-size: 16rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10rem;
    transition:
      background 0.3s,
      border 0.3s;
    &:hover {
      border: 1rem solid var(--gray-light, #f2f4f6);
      background: var(--gray-light, #f2f4f6);
    }
  }

  &__delete {
    cursor: pointer;
    width: 160rem;
    height: 61rem;
    border-radius: 66rem;
    border: 1rem solid #f43535;
    background: #f43535;

    color: var(--white, #fff);
    text-align: center;
    font-family:
      Italian Plate No2 Expanded,
      sans-serif;
    font-size: 16rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    transition:
      background 0.3s,
      border 0.3s;
    &:hover {
      border: 1rem solid #cc2c2c;
      background: #cc2c2c;
    }
  }

  &__send {
    cursor: pointer;
    width: 160rem;
    height: 61rem;
    border-radius: 66rem;
    border: 1rem solid #000;
    background: #000;
    color: var(--white, #fff);
    text-align: center;
    font-family:
      Italian Plate No2 Expanded,
      sans-serif;
    font-size: 16rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    transition:
      background 0.3s,
      border 0.3s;
    &:hover {
      border: 1rem solid var(--black, #000);
      background: #fff;
      color: #000;
    }
  }

  &-close {
    position: absolute;
    top: 16rem;
    right: 16rem;
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
