.creatives {
  margin-top: 24rem;
  &-album {
    border-radius: 16rem;
    background: var(--white, #fff);
    display: flex;
    gap: 12rem;
    justify-content: start;
    flex-wrap: wrap;

    .creatives-item {
      width: calc(25% - 9rem);
    }
  }
  &-item {
    border-radius: 12rem;
    overflow: hidden;
    background: #f2f4f6;
    transition: 0.3s;
    margin-bottom: 11rem;
    cursor: pointer;
    width: 100%;

    &:hover {
      position: relative;
      z-index: 1;
      border-radius: 12rem;
      background: #f2f4f6;
      box-shadow: 0rem 0rem 16rem 0rem #b4bcc6;
    }

    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12rem 16rem;
      border-bottom: 1rem solid #e5e8ec;
      margin-bottom: 16rem;
    }

    &__title {
      color: #000;
      font-family:
        Italian Plate No2 Expanded,
        sans-serif;
      font-size: 16rem;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      /* 20.8rem */
      padding: 0 16rem;
      margin-bottom: 16rem;
    }

    &__date {
      color: #a7a8aa;
      font-family:
        Italian Plate No2 Expanded,
        sans-serif;
      font-size: 14rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding: 0 16rem;
      margin-bottom: 6rem;
    }

    &__img {
      position: relative;
    }

    &__ai {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 141rem;
      // background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      padding: 8rem;
      display: flex;

      span {
        margin-right: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 6rem;
        height: 28rem;
        border-radius: 19rem;
        background: var(--white, #fff);
        color: #000;
        font-family:
          Italian Plate No2 Expanded,
          sans-serif;
        font-size: 14rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        width: min-content;
        white-space: nowrap;

        svg {
          width: 16rem;
          height: 16rem;
          margin-right: 6rem;
        }
      }
    }

    img {
      margin-bottom: 0 !important;
    }

    // &-info {

    // }

    &__social {
      color: #000;
      font-family:
        Italian Plate No2 Expanded,
        sans-serif;
      font-size: 14rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    &__size {
      color: #a7a8aa;
      text-align: right;
      font-family:
        Italian Plate No2 Expanded,
        sans-serif;
      font-size: 14rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  &-modal {
    &-close {
      position: absolute;
      top: -36rem;
      right: -36rem;
      cursor: pointer;

      svg {
        width: 36rem;
        height: 36rem;
      }
    }

    &__img {
      display: grid;
      grid-template-columns: 354rem 270rem;
      grid-template-rows: 333rem 270rem;
      gap: 24rem;
      img {
        border-radius: 16rem;
      }
      img:nth-child(1) {
        grid-row: span 2;
      }
      img:nth-child(2) {
        grid-column: span 1;
        grid-row: span 1;
      }
      img:nth-child(3) {
        grid-column: span 1;
        grid-row: span 1;
      }
    }
  }

  &-block {
    border-radius: 16rem;
    background: var(--white, #fff);
    padding: 32rem 32rem 36rem;
    display: flex;
    margin-bottom: 40rem;
    &-item {
      width: 160rem;

      span {
        display: block;
        color: #a7a8aa;
        font-family:
          Italian Plate No2 Expanded,
          sans-serif;
        font-size: 14rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 10rem;
      }

      strong {
        color: #000;
        font-family:
          Italian Plate No2 Expanded,
          sans-serif;
        font-size: 24rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
