.response-error {
  width: 100%;
  min-height: 500rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;

  &__icon {
    width: 50rem;
    height: 50rem;
    margin-bottom: 32rem;
  }

  &__title {
    font-size: 24rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    margin-bottom: 5rem;
  }

  &__sub-title {
    color: #8f969c;
    font-size: 14rem;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 27rem;
  }

  &__btn {
    color: #fff;
    font-size: 14rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 100rem;
    background: #000;
    padding: 16rem;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 6rem;
  }
}
