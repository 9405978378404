.snackbar {
  position: fixed;
  bottom: 40rem;
  left: 100rem;
  border-radius: 16rem;
  background: var(--black, #000);
  padding: 26rem 32rem;
  z-index: 100;
  max-width: 358rem;
  display: flex;
  align-items: center;

  &-text {
    color: #fff;
    font-family:
      Italian Plate No2 Expanded,
      sans-serif;
    font-size: 15rem;
    font-style: normal;

    font-weight: 600;
    line-height: 130%;

    span {
      font-weight: 400;
    }
  }

  &-progress {
    width: 30rem;
    min-width: 30rem;
    height: 30rem;
    position: relative;
    // z-index: 1;
    margin-right: 12rem;
    border-radius: 50%;
    overflow: hidden;

    &-count {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: var(--white, var(--white, #fff));
      text-align: center;
      font-family:
        Italian Plate No2 Expanded,
        sans-serif;
      font-size: 15rem;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      margin-top: 1rem;
    }

    &-main {
      position: absolute;
      top: 0;
      left: 0;

      z-index: 1;
    }

    &-bg {
      opacity: 0.2;
      position: relative;
    }
  }
}
