.table .MuiTableCell-body.MuiTableCell-sizeMedium,
.table .MuiTableCell-head.MuiTableCell-sizeMedium {
  border-right: none;
  text-align: left;
}

.promotionsNew-AllPromotions {
  &-img {
    position: relative;
    width: 54rem;
    min-width: 54rem;
    height: 54rem;
    min-height: 54rem;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8rem;
      border: 1px solid #f5f5f5;
      position: relative;
      z-index: 1;

      &:nth-child(2) {
        position: absolute;
        top: 5rem;
        left: 5rem;
        z-index: 0;
      }
    }

    span {
      position: absolute;
      bottom: -8rem;
      right: -8rem;
      background: #000;
      width: 16rem;
      height: 16rem;
      border-radius: 50%;
      font-weight: 600;
      font-size: 10rem;
      text-align: center;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;
    }
  }

  &-info {
    width: 350rem;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      width: 100%;
      margin-bottom: 8rem;
    }

    &-bottom {
      display: flex;
      align-items: center;
    }
  }

  &-status {
    border-radius: 10rem;
    padding: 0px 12rem;
    height: 32rem;
    display: flex;
    align-items: center;
    margin-right: 8rem;
    font-weight: 600;
    font-size: 16rem;
    color: #000;
    background: #f2f4f6;

    span {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      display: block;
      margin-right: 6rem;
      background: #8f969c;
    }

    &--Active {
      background: #effdf6;

      span {
        background: #17cc37;
      }
    }

    &--Rejected {
      background: #fef4f6;

      span {
        background: #ef2c4f;
      }
    }

    &--Pending {
      background: #FFF8DD;

      span {
        background: #EFC836;
      }
    }
  }

  &-website {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border: 1px solid #f2f4f6;
    border-radius: 10rem;
    padding: 7rem 12rem;
    height: 32rem;
    font-weight: 500;
    font-size: 16rem;
    color: #000;
  }

  &-date {
    width: 205rem;
    font-weight: 400;
    font-size: 16rem;
    line-height: 140%;
    color: #000;
    display: block;
  }
}

.promotionsPageNew {
  .userInfo-block-item > span {
    font-weight: 500;
    font-size: 16rem;
    color: #a7a8aa;
  }
  .promotionsPageNew-top .userInfo-block-item p {
    font-weight: 400;
  }
}
