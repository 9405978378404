.videos-images {
  height: 100%;

  &__title {
    font-weight: 500;
    font-size: 14rem;
    color: #a7a8aa;
    margin-bottom: 20rem;
  }

  &-video {
    img {
      width: 100%;
      aspect-ratio: 358/628;
      object-fit: cover;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5rem;
    }
  }

  &-container {
    display: grid;
    grid-template-columns: 305rem 1fr;
    grid-gap: 32rem;
  }

  &-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15rem;
    margin-bottom: 24rem;
  }

  &__img {
    position: relative;

    img {
      border: 1px solid #f2f4f6;
      border-radius: 12rem;
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
    }

    span {
      position: absolute;
      top: 8rem;
      right: 8rem;
      width: 18rem;
      height: 18rem;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 5rem;
      background: #000;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 1rem;
      font-weight: 700;
      font-size: 12rem;
      line-height: 120%;
      text-align: center;
      color: #fff;
      z-index: 1;
    }
  }

  &__video {
    position: relative;

    svg {
      position: absolute;
      width: 20rem;
      height: 20rem;
      top: 8rem;
      left: 8rem;
    }

    i {
      font-weight: 600;
      font-size: 15px;
      color: #fff;
      position: absolute;
      right: 12rem;
      bottom: 12rem;
      z-index: 1;
      font-style: normal;
    }

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 82rem;
      bottom: 0;
      left: 0;
      border-radius: 0 0 12rem 12rem;
      background: linear-gradient(167deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    }
  }
}
