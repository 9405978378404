.about {
  display: grid;
  grid-gap: 50rem;
  &-block {
    background: #ffffff;
    padding: 40rem 32rem;
    border-radius: 16rem;
    font-family:
      Italian Plate No2 Expanded,
      sans-serif;

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 32rem;
    }

    &__title {
      font-weight: 600;
      font-size: 24rem;
      line-height: 1.2;
      color: #000;
    }

    &__complete {
      font-weight: 400;
      font-size: 16rem;
      line-height: 1.2;
      color: #000;
    }

    &__progress {
      width: 100%;
      height: 4rem;
      border-radius: 5rem;
      background: #e9eeff;
      margin-top: 18rem;

      span {
        display: block;
        height: 100%;
        border-radius: 5rem;
        background: #2555ff;
      }
    }

    &-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 50rem;
      grid-row-gap: 40rem;
    }

    &-item {
      span {
        display: block;
        font-weight: 500;
        font-size: 14rem;
        line-height: 1.2;
        color: #a7a8aa;
        margin-bottom: 10rem;
      }

      p {
        font-weight: 500;
        font-size: 16rem;
        line-height: 1.2;
        color: #000;
        margin-bottom: 10rem;
      }
      ul {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 70rem;
      }
    }
  }
  &-bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 32rem;
  }
}
