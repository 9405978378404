.loader {
  min-height: 500rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16rem;

  &__title {
    font-size: 24rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }
}
