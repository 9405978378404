.filter {
  position: relative;
  display: flex;

  width: 100%;

  &-btn {
    height: 48rem;
    padding: 0 15rem;
    display: flex;
    align-items: center;
    border-radius: 8rem;
    background: #f2f4f6;
    cursor: pointer;
    svg {
      width: 18rem;
      height: 18rem;
      margin-right: 11rem;
    }

    color: #000;

    font-size: 14rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    transition: 0.3s;
    &:hover,
    &.active {
      background: #dddfe0;
    }
  }

  &-result {
    width: 100%;

    &-clear {
      border-radius: 100rem;
      border: 1rem solid #f43535;
      margin-left: auto;
      padding: 0 24rem;
      height: 48rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #f43535;
      text-align: center;
      font-size: 16rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }
  }

  &-dropdown {
    position: absolute;
    left: 0;
    top: calc(100% + 6rem);
    border-radius: 8rem;
    border: 1rem solid #f2f4f6;
    background: var(--white, #fff);
    box-shadow: 0rem 7rem 41rem -6rem rgba(0, 0, 0, 0.08);
    width: 280rem;
    z-index: 1;
    &-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24rem;
      padding-bottom: 6rem;
    }

    &__title {
      color: #000;
      font-size: 18rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &__close {
      color: #f43535;
      text-align: center;
      font-size: 16rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }

    &-accordion {
      label {
        display: flex;
        align-items: center;
        margin-bottom: 20rem;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }

        color: #000;
        font-size: 14rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        input {
          width: 0;
          height: 0;
          visibility: hidden;
          pointer-events: none;
          opacity: 0;

          &:checked + .filter-dropdown-checkbox {
            svg {
              opacity: 1;
            }
          }
        }
      }
      .createdAt {
        display: flex;
        align-items: center;
        label {
          margin-bottom: 0;
        }
      }
      .analytics-top-date-block {
        right: auto;
        left: 50%;
        transform: translateX(-50%);
      }
      .analytics-top-date {
        width: 100%;
      }
      .analytics-top-date-arrow {
        margin-left: 5rem;
      }
    }

    &-checkbox {
      width: 20rem;
      height: 20rem;
      border-radius: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      margin-right: 8rem;

      svg {
        width: 10rem;
        height: 10rem;
        transition: 0.3s;
        opacity: 0;
      }
    }
  }

  &-results {
    display: flex;
    flex-wrap: wrap;
    margin-left: 12rem;
    &.active {
      row-gap: 12rem;
      .filter-results-item {
        display: flex !important;
      }
    }
    &-more {
      display: flex;
      border-radius: 8rem;
      border: 1rem solid #f2f4f6;
      background: #f2f4f6;
      height: 48rem;
      align-items: center;
      padding: 0 15rem;
      color: #000;
      font-size: 14rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      &.active {
        display: none;
      }
      span {
        width: 24rem;
        height: 24rem;
        background: #000;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5rem;
        color: var(--white, #fff);
        font-size: 14rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      svg {
        width: 16rem;
        height: 16rem;
        margin-left: 8rem;
      }
    }
    &-less {
      border-radius: 8rem;
      border: 1rem solid #f2f4f6;
      background: #f2f4f6;
      height: 48rem;
      align-items: center;
      padding: 0 15rem;
      color: #000;
      font-size: 14rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      display: none;
      &.active {
        display: flex;
      }
      span {
        width: 24rem;
        height: 24rem;
        background: #000;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5rem;
        color: var(--white, #fff);
        font-size: 14rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      svg {
        width: 16rem;
        height: 16rem;
        margin-left: 8rem;
        transform: rotate(180deg);
      }
    }

    &-top {
      display: flex;
    }

    &-bottom {
      display: flex;
    }

    .filtered-results {
      display: flex;
    }

    &-item {
      height: 48rem;
      padding: 0 15rem;
      display: flex;
      align-items: center;
      border-radius: 8rem;
      border: 1rem solid #f2f4f6;
      color: #000;
      font-size: 14rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-right: 12rem;

      label {
        display: flex;
        cursor: pointer;

        svg {
          width: 24rem;
          height: 24rem;
        }

        margin-left: 10rem;
      }

      input {
        width: 0;
        height: 0;
        visibility: hidden;
        pointer-events: none;
        opacity: 0;
      }
    }
  }

  .MuiAccordion-root {
    box-shadow: none;
    border-bottom: 1rem solid #f2f4f6;

    &:last-child {
      border-bottom: none;
    }

    &::before {
      display: none;
    }
  }

  .MuiAccordionSummary-root {
    min-height: auto;
    height: 52rem;
    padding: 0 24rem;
    display: flex;
    align-items: center;
  }

  .MuiAccordionSummary-content {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #a7a8aa;
    font-size: 16rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    transition: 0.3s;

    svg {
      width: 16rem;
      height: 16rem;
      transition: 0.3s;
    }
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
    color: #000;

    svg {
      transform: rotate(180deg);
    }
  }

  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionDetails-root {
    padding: 22rem 24rem;
    background: #f2f4f6;
  }
  .MuiButtonBase-root:hover {
    .MuiAccordionSummary-content {
      color: #000;
    }
  }
}
