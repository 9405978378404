.empty-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 500rem;
  gap: 10rem;

  &__icon {
    width: 165rem;
    height: 165rem;
  }

  &__title {
    font-size: 24rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }
}
