.activity {
  &__title {
    color: #a7a8aa;
    font-size: 20rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 24rem;
    font-family:
      Italian Plate No2 Expanded,
      sans-serif;
  }

  &-block {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20rem;

    &-top {
      display: flex;
      align-items: center;
      padding: 24rem 29rem 24rem;

      &__title {
        color: #000;
        font-family:
          Italian Plate No2 Expanded,
          sans-serif;
        font-size: 22rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 12rem;
      }

      &-info {
        width: 140rem;
      }

      p {
        color: #a7a8aa;
        font-family:
          Italian Plate No2 Expanded,
          sans-serif;
        font-size: 16rem;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
      }
    }

    &-progress {
      width: 120rem;
      min-width: 120rem;
      height: 120rem;
      position: relative;
      margin-right: 16rem;

      &-main {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      &__count {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #000;
        text-align: center;
        font-family:
          Italian Plate No2 Expanded,
          sans-serif;
        font-size: 22rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    &-item {
      border-radius: 16rem;
      background: var(--white, #fff);
      height: min-content;
      li {
        display: flex;
        padding: 19.5rem 30rem;
        align-items: center;
        border-top: 1rem solid #f2f4f6;

        .activity-block__tick {
          width: 22rem;
          min-width: 22rem;
          height: 22rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: #e5e8ec;
          margin-right: 12rem;

          svg {
            width: 18rem;
            height: 19rem;
            opacity: 0;
          }
        }

        p {
          color: #000;
          font-family:
            Italian Plate No2 Expanded,
            sans-serif;
          font-size: 16rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        &.active {
          .activity-block__tick {
            &--green {
              background: #17cc37;
            }
            &--blue {
              background: #1a9fff;
            }
            &--orange {
              background: #ffb628;
            }
            svg {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &New {
    &-block {
      &-wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 8rem;
      }
      &-item {
        display: flex;
        svg {
          width: 22rem;
          min-width: 22rem;
          height: 22rem;
          margin-right: 12rem;
        }
        &__circle {
          width: 22rem;
          min-width: 22rem;
          height: 22rem;
          margin-right: 12rem;
          border-radius: 50%;
          border: 1rem solid #cad4dd;
        }
        span {
          font-weight: 500;
          color: #000;
          font-size: 16rem;
          line-height: 1.4;
          margin-bottom: 8rem;
          display: block;
        }
        p {
          font-weight: 400;
          color: #a7a8aa;
          font-size: 14rem;
          line-height: 1.4;
        }
      }
    }
  }
}
